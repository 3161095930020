// General
import "./profile-completion.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCompletionQuery,
  useLazyGetJourneyQuestionsQuery,
} from "../../../services/data.service";
// Static Data
import editProfileConst from "../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Public Photos Functions
  updateProfilePhoto as updateEditProfilePhoto,
  updatePublicPhotos as updateEditPublicPhotos,

  // Private Photos Functions
  updatePrivatePhotos as updateEditPrivatePhotos,

  // Basic Information Functions
  updateEmail,
  updateUsername as updateEditUsername,
  updateAge as updateEditAge,
  updateBirthdate as updateEditBirthdate,

  // TOR Functions
  updateTor as updateEditTor,

  // Dating Styles Functions
  updateDatingStyles as updateEditDatingStyles,

  // Financial Information Functions
  updateLifestyle as updateEditLifestyle,
  updateNetworth as updateEditNetworth,
  updateAnnualIncome as updateEditAnnualIncome,
  updateDatingBudget as updateEditDatingBudget,

  // Locations Functions

  // Appearance Functions
  updateHeight as updateEditHeight,
  updateBodyType as updateEditBodyType,
  updateEthnicity as updateEditEthnicity,
  updateRace as updateEditRace,
  updateEyeColor as updateEditEyeColor,
  updateHairColor as updateEditHairColor,

  // Personal Information Functions
  updateOccupation as updateEditOccupation,
  updatePosition as updateEditPosition,
  updateIndustry as updateEditIndustry,
  updateEducation as updateEditEducation,
  updateRelationshipStatus as updateEditRelationshipStatus,
  updateChildren as updateEditChildren,
  updateSmoking as updateEditSmoking,
  updateDrinking as updateEditDrinking,
  updateAlcoholPreference as updateEditAlcoholPreference,

  // About Me Functions
  updateAboutMe as updateEditAboutMe,
  updateAboutMeList as updateEditAboutMeList,
  updateAboutMeLength as updateEditAboutMeLength,
  updatePersonalityTraits,
  updatePersonalityType,
  updateStarSign,
  updateDominantOrSubmissive,
  updateFitnessLevel,
  updateHobbies,
  updateLookingFor as updateEditLookingFor,
  updateTagline as updateEditTagline,

  // Status Functions
  updateUsernameStatus,
  updateUsernameNewContent,

  // Location Functions
  updateLocation as updateEditLocation,
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,

  // Utility Functions
  updateUpdateProfileDialogView,
  updateUpdateProfileFullscreenTab,
  updateProfileCompletionState,
} from "../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../redux/store/profileStore";
import {
  updateUpdateProfileFullscreenDialog,
  updateUpdateTorDialog,
} from "../../../redux/store/dialogStore";
// Material UI
import { CircularProgress, ButtonBase } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import routeConst from "../../../const/routeConst";

const ProfileCompletion = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();

  // Redux variables
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getProfileCompletion(null, false);
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | getProfileCompletion API Response
  useEffect(() => {
    if (getProfileCompletionFetching || getProfileCompletionLoading) {
    } else if (getProfileCompletionSuccess) {
      if (getProfileCompletionData?.status === 0) {
        dispatch(
          updateProfileCompletionPercentage(
            getProfileCompletionData?.data?.profile_progress?.summary?.progress
          )
        );
        dispatch(
          updateProfileRequirementPopup(
            getProfileCompletionData?.data?.profile_progress?.summary
              ?.requirement
          )
        );

        // Public Photos
        dispatch(
          updateEditProfilePhoto(getProfileCompletionData?.data?.profile_photo)
        );
        dispatch(
          updateEditPublicPhotos(
            getProfileCompletionData?.data?.public_photos?.filter(
              (photo) =>
                photo?.id !== getProfileCompletionData?.data?.profile_photo?.id
            )
          )
        );

        // Private Photos
        dispatch(
          updateEditPrivatePhotos(
            getProfileCompletionData?.data?.private_photos
          )
        );

        // Basic Information
        dispatch(updateEmail(getProfileCompletionData?.data?.email));
        dispatch(updateEditUsername(getProfileCompletionData?.data?.username));
        dispatch(
          updateEditAge(getProfileCompletionData?.data?.appearance?.age)
        );
        dispatch(
          updateEditBirthdate(
            getProfileCompletionData?.data?.appearance?.birth_date
          )
        );

        // TOR
        dispatch(updateEditTor(getProfileCompletionData?.data?.tor_tags));

        // Dating Style
        dispatch(
          updateEditDatingStyles(
            getProfileCompletionData?.data?.relationship?.dating_style
          )
        );

        // Financial Information
        dispatch(
          updateEditLifestyle(
            getProfileCompletionData?.data?.occupation?.lifestyle_budget
          )
        );
        dispatch(
          updateEditDatingBudget(
            getProfileCompletionData?.data?.relationship?.dating_budget
          )
        );
        dispatch(
          updateEditAnnualIncome(
            getProfileCompletionData?.data?.occupation?.annual_income
          )
        );
        dispatch(
          updateEditNetworth(
            getProfileCompletionData?.data?.occupation?.networth
          )
        );

        // Location

        // Appearance
        dispatch(
          updateEditHeight(getProfileCompletionData?.data?.appearance?.height)
        );
        dispatch(
          updateEditBodyType(
            getProfileCompletionData?.data?.appearance?.body_type
          )
        );
        dispatch(
          updateEditEthnicity(
            getProfileCompletionData?.data?.appearance?.ethnicity
          )
        );
        dispatch(
          updateEditRace(getProfileCompletionData?.data?.appearance?.race)
        );
        dispatch(
          updateEditEyeColor(
            getProfileCompletionData?.data?.appearance?.eye_color
          )
        );
        dispatch(
          updateEditHairColor(
            getProfileCompletionData?.data?.appearance?.hair_color
          )
        );

        // Personal Information
        dispatch(
          updateEditOccupation(
            getProfileCompletionData?.data?.occupation?.occupation
          )
        );
        dispatch(
          updateEditPosition(
            getProfileCompletionData?.data?.occupation?.position
          )
        );
        dispatch(
          updateEditIndustry(
            getProfileCompletionData?.data?.occupation?.industry
          )
        );
        dispatch(
          updateEditEducation(
            getProfileCompletionData?.data?.occupation?.education
          )
        );
        dispatch(
          updateEditRelationshipStatus(
            getProfileCompletionData?.data?.relationship?.relationship_status
          )
        );
        dispatch(
          updateEditChildren(
            getProfileCompletionData?.data?.relationship?.children
          )
        );
        dispatch(
          updateEditSmoking(getProfileCompletionData?.data?.habit?.smoking)
        );
        dispatch(
          updateEditDrinking(getProfileCompletionData?.data?.habit?.drinking)
        );
        dispatch(
          updateEditAlcoholPreference(
            getProfileCompletionData?.data?.habit?.alcohol
          )
        );

        // About Me
        const personalityType =
          getProfileCompletionData?.data?.personality?.personality_type;
        const hobbies = getProfileCompletionData?.data?.habit?.hobbies;
        const fitnessLevel =
          getProfileCompletionData?.data?.habit?.fitness_level;
        const starSign = getProfileCompletionData?.data?.personality?.star_sign;
        const dominantOrSubmissive =
          getProfileCompletionData?.data?.personality?.dominant_or_submissive;
        const personalityTraits =
          getProfileCompletionData?.data?.personality?.personality_traits;

        dispatch(updatePersonalityTraits(personalityTraits));
        dispatch(updatePersonalityType(personalityType));
        dispatch(updateStarSign(starSign));
        dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
        dispatch(updateFitnessLevel(fitnessLevel));
        dispatch(updateHobbies(hobbies));

        let tempAboutMeList = [];
        let tempAboutMeLength = 0;

        tempAboutMeList?.push({
          name: "personality_type",
          data: personalityType?.length > 0 ? personalityType : null,
        });

        if (personalityType?.length > 0) {
          tempAboutMeLength++;
        }

        tempAboutMeList.push({
          name: "hobbies",
          data: hobbies?.length > 0 ? hobbies : null,
        });

        if (hobbies?.length > 0) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "star_sign",
          data: starSign,
        });

        if (starSign) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "fitness_level",
          data: fitnessLevel,
        });

        if (fitnessLevel) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "dominant_or_submissive",
          data: dominantOrSubmissive,
        });

        if (dominantOrSubmissive) {
          tempAboutMeLength++;
        }

        tempAboutMeList?.push({
          name: "personality_traits",
          data: personalityTraits,
        });

        if (personalityTraits) {
          tempAboutMeLength++;
        }

        dispatch(updateEditAboutMeList(tempAboutMeList));
        dispatch(updateEditAboutMeLength(tempAboutMeLength));

        dispatch(updateEditAboutMe(getProfileCompletionData?.data?.aboutme));

        // Looking For
        dispatch(
          updateLookingForAgeRange({
            min: getProfileCompletionData?.data?.looking_fors?.age?.min,
            max: getProfileCompletionData?.data?.looking_fors?.age?.max,
          })
        );
        dispatch(
          updateLookingForLocation(
            getProfileCompletionData?.data?.looking_fors?.locations
          )
        );
        const temp =
          getProfileCompletionData?.data?.looking_fors?.ethnicity?.map(
            (item) => ({
              ethnicity: {
                id: item?.option?.id,
                locale: item?.option?.locale,
                display_locale: item?.option?.locale,
              },
              race: item?.sub_option
                ? {
                    id: item?.sub_option?.id,
                    locale: item?.sub_option?.locale,
                    display_locale: item?.sub_option?.locale,
                  }
                : null,
            })
          );
        dispatch(updateLookingForEthnicityRace(temp));

        dispatch(
          updateEditLookingFor(getProfileCompletionData?.data?.lookingfor)
        );
        dispatch(updateEditTagline(getProfileCompletionData?.data?.tagline));

        const locationObj = {
          lat: getProfileCompletionData?.data?.location?.latitude,
          lng: getProfileCompletionData?.data?.location?.longitude,
          location_data: {
            city: getProfileCompletionData?.data?.location?.city,
            state: getProfileCompletionData?.data?.location?.state,
            formatted_address:
              getProfileCompletionData?.data?.location?.full_address || "",
            country: getProfileCompletionData?.data?.location?.country,
          },
        };

        dispatch(updateEditLocation(locationObj));

        if (
          getProfileCompletionData?.data?.profile_progress?.summary?.progress >=
          100
        ) {
          onNavigate(routeConst.profile.overview.path);
        }
      }
    } else if (getProfileCompletionError) {
    }
  }, [
    getProfileCompletionFetching,
    getProfileCompletionLoading,
    getProfileCompletionSuccess,
    getProfileCompletionError,
  ]);

  // Event Handlers | Button
  const onOpenUpdateProfileDialog = (type) => {
    dispatch(updateUpdateProfileFullscreenTab(0));

    switch (type) {
      case editProfileConst.groupProfileDialogView.photos:
        dispatch(
          updateUpdateProfileDialogView(
            editProfileConst.profileDialogView.photos
          )
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.tor:
        dispatch(
          updateUpdateProfileDialogView(editProfileConst.profileDialogView.tor)
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.datingStyle:
        dispatch(
          updateProfileCompletionState({ name: "datingStyle", slide: 0 })
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.financialInformation:
        const lifestyle =
          getProfileCompletionData?.data?.occupation?.lifestyle_budget;
        const networth = getProfileCompletionData?.data?.occupation?.networth;
        const annualIncome =
          getProfileCompletionData?.data?.occupation?.annual_income;
        const datingBudget =
          getProfileCompletionData?.data?.relationship?.dating_budget;

        if (isDaddy) {
          if (!datingBudget) {
            dispatch(updateUpdateProfileFullscreenTab(0));
          } else if (!networth) {
            dispatch(updateUpdateProfileFullscreenTab(1));
          } else if (!annualIncome) {
            dispatch(updateUpdateProfileFullscreenTab(2));
          }
        } else {
          if (!lifestyle) {
            dispatch(updateUpdateProfileFullscreenTab(0));
          }
        }

        dispatch(
          updateProfileCompletionState({
            name: "financialInformation",
            slide: onReturnMaxLabel("financial"),
          })
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.personalInformation:
        const position = getProfileCompletionData?.data?.occupation?.position;
        const industry = getProfileCompletionData?.data?.occupation?.industry;
        const education = getProfileCompletionData?.data?.occupation?.education;
        const relationshipStatus =
          getProfileCompletionData?.data?.relationship?.relationship_status;
        const children = getProfileCompletionData?.data?.relationship?.children;
        const smoking = getProfileCompletionData?.data?.habit?.smoking;
        const drinking = getProfileCompletionData?.data?.habit?.drinking;
        const alcoholPreference =
          getProfileCompletionData?.data?.habit?.alcohol;

        if (isDaddy && (!position || !industry)) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!isDaddy && !position) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!education) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!relationshipStatus) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!children && children !== 0) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!smoking) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        } else if (!drinking) {
          dispatch(updateUpdateProfileFullscreenTab(5));
        } else if (!alcoholPreference || alcoholPreference?.length <= 0) {
          dispatch(updateUpdateProfileFullscreenTab(6));
        }

        dispatch(
          updateProfileCompletionState({
            name: "personalInformation",
            slide: onReturnMaxLabel("personalInformation"),
          })
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.appearance:
        const height = getProfileCompletionData?.data?.appearance?.height;
        const bodyType = getProfileCompletionData?.data?.appearance?.body_type;
        const ethnicity = getProfileCompletionData?.data?.appearance?.ethnicity;
        const race = getProfileCompletionData?.data?.appearance?.race;
        const eyeColor = getProfileCompletionData?.data?.appearance?.eye_color;
        const hairColor =
          getProfileCompletionData?.data?.appearance?.hair_color;

        if (!height) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!bodyType) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!ethnicity) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!race) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!eyeColor) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!hairColor) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        }

        dispatch(
          updateProfileCompletionState({
            name: "appearance",
            slide: onReturnMaxLabel("appearance"),
          })
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.aboutMe:
        dispatch(
          updateUpdateProfileDialogView(
            editProfileConst.profileDialogView.aboutMe
          )
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case editProfileConst.groupProfileDialogView.lookingFor:
        dispatch(
          updateProfileCompletionState({
            name: "lookingFor",
            slide: onReturnMaxLabel("lookingFor"),
          })
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const onReturnMissingLabel = (state) => {
    switch (state) {
      case "photos":
        return getProfileCompletionData?.data?.profile_progress?.profile?.photos
          ?.completed;
      case "tor":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.tor
            ?.completed || 0
        );
      case "datingStyle":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile
            ?.dating_style?.completed || 0
        );
      case "financial":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.financial
            ?.completed || 0
        );
      case "appearance":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.appearance
            ?.completed || 0
        );
      case "personalInformation":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile
            ?.personal_info?.completed || 0
        );
      case "aboutMe":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.about_me
            ?.completed || 0
        );
      case "lookingFor":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.looking_for
            ?.completed || 0
        );
      default:
        break;
    }
  };
  const onReturnMaxLabel = (state) => {
    switch (state) {
      case "photos":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.photos
            ?.required || 4
        );
      case "tor":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.tor
            ?.required || 5
        );
      case "datingStyle":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile
            ?.dating_style?.required || 6
        );
      case "financial":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.financial
            ?.required || 1
        );
      case "appearance":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.appearance
            ?.required || 6
        );
      case "personalInformation":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile
            ?.personal_info?.required || 7
        );
      case "aboutMe":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.about_me
            ?.required || 3
        );
      case "lookingFor":
        return (
          getProfileCompletionData?.data?.profile_progress?.profile?.looking_for
            ?.required || 3
        );
      default:
        break;
    }
  };

  if (getProfileCompletionData && getJourneyQuestionsData) {
    return (
      <div id="profile-completion-page">
        <div className="max-width-container">
          <UtilityDesktopNavbar leftButton={"back"} />

          <div className="padding-container">
            <div className="circular-progress-bar-container">
              <CircularProgress
                className="default-circular-progress-bar"
                variant="determinate"
                value={100}
              />

              <CircularProgress
                className="circular-progress-bar"
                variant="determinate"
                value={profileCompletionPercentage || 0}
              />

              <div className="profile-completion-percentage-label">
                {profileCompletionPercentage || 0}%
              </div>
            </div>

            <div className="profile-completion-title">
              {t("profile.completion.title")}
            </div>

            <div className="profile-completion-description">
              {t("profile.completion.description")}
            </div>

            <div className="buttons-list-container">
              {/* Photos */}
              {onReturnMissingLabel("photos") < onReturnMaxLabel("photos") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.profileDialogView.photos
                    )
                  }
                >
                  <div className="button-label">{t("profile.edit_photos")}</div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("photos"),
                        max: onReturnMaxLabel("photos"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* TOR */}
              {onReturnMissingLabel("tor") < onReturnMaxLabel("tor") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.profileDialogView.tor
                    )
                  }
                >
                  <div className="button-label">
                    <Trans
                      i18nKey={"profile.terms_of_relationship"}
                      components={{ sup: <sup className="trade-mark-label" /> }}
                    />
                  </div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("tor"),
                        max: onReturnMaxLabel("tor"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* Dating Style */}
              {onReturnMissingLabel("datingStyle") <
                onReturnMaxLabel("datingStyle") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.groupProfileDialogView.datingStyle
                    )
                  }
                >
                  <div className="button-label">
                    {t("profile.dating_style")}
                  </div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("datingStyle"),
                        max: onReturnMaxLabel("datingStyle"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* Financial Information */}
              {onReturnMissingLabel("financial") <
                onReturnMaxLabel("financial") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.groupProfileDialogView
                        .financialInformation
                    )
                  }
                >
                  <div className="button-label">
                    {t("profile.financial_information")}
                  </div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("financial"),
                        max: onReturnMaxLabel("financial"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* Appearance */}
              {onReturnMissingLabel("appearance") <
                onReturnMaxLabel("appearance") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.groupProfileDialogView.appearance
                    )
                  }
                >
                  <div className="button-label">{t("profile.appearance")}</div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("appearance"),
                        max: onReturnMaxLabel("appearance"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* Personal Information */}
              {onReturnMissingLabel("personalInformation") <
                onReturnMaxLabel("personalInformation") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.groupProfileDialogView
                        .personalInformation
                    )
                  }
                >
                  <div className="button-label">
                    {t("profile.personal_information")}
                  </div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("personalInformation"),
                        max: onReturnMaxLabel("personalInformation"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* About Me */}
              {onReturnMissingLabel("aboutMe") <
                onReturnMaxLabel("aboutMe") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.groupProfileDialogView.aboutMe
                    )
                  }
                >
                  <div className="button-label">{t("profile.about_me")}</div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("aboutMe"),
                        max: onReturnMaxLabel("aboutMe"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}

              {/* Looking For */}
              {onReturnMissingLabel("lookingFor") <
                onReturnMaxLabel("lookingFor") && (
                <ButtonBase
                  className="button-container"
                  component="div"
                  onClick={() =>
                    onOpenUpdateProfileDialog(
                      editProfileConst.groupProfileDialogView.lookingFor
                    )
                  }
                >
                  <div className="button-label">{t("profile.looking_for")}</div>
                  <div className="suffix">
                    <div className="individual-progress-container">
                      {t("profile.completion.n_of_max_added", {
                        n: onReturnMissingLabel("lookingFor"),
                        max: onReturnMaxLabel("lookingFor"),
                      })}
                    </div>
                    <NavigateNextIcon />
                  </div>
                </ButtonBase>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    getProfileCompletionFetching ||
    getProfileCompletionLoading ||
    getJourneyQuestionsFetching ||
    getJourneyQuestionsLoading
  ) {
    return <Spinner />;
  }
};

export default ProfileCompletion;
