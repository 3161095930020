// General
import "./go-live-button.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { ButtonBase } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// react-device-detect
import { isAndroid, isIOS } from "react-device-detect";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GoLiveButton = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
  }, [isLoggedIn]);

  const onOpenDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GoLive-Button",
      },
    });

    if (isAndroid) {
      window.open(routeConst.misc.sugarbookAndroidApk.path, "_blank");
      return;
    } else if (isIOS) {
      window.open(routeConst.misc.sugarbookIosApp.path, "_blank");
      return;
    }
  };

  if (
    getUserAioData?.data?.modules?.live_streaming_enabled &&
    (isAndroid || isIOS) &&
    getUserAioData?.data?.live_streaming?.user?.is_allowed_live_streaming
  ) {
    return (
      <ButtonBase
        id="go-live-button-shared-subcomponent"
        component="div"
        onClick={onOpenDialog}
      >
        <div className="live-icon-container">
          {getIcon("goLivePlayIcon", "live-icon")}
        </div>
        <div className="label">{t("1on1.go_live")}</div>
      </ButtonBase>
    );
  }
};

export default GoLiveButton;
