// General
import "./user-info.scss";
import { useEffect } from "react";
// Services
import {
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamerIsFollowing } from "../../../../../redux/store/livestreamingStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  updateShowLastActive,
  updateLastActive,
  updateShowMemberSince,
  updateMemberSince,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateStarSign,
  updateFitnessLevel,
  updateDominantOrSubmissive,
  updatePersonalityTraits,
  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,

  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../redux/store/userCardStore";
import { updateUserProfileDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, ButtonBase } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../elements/custom-avatar/custom-avatar";
import Spinner from "../../../elements/spinner/spinner";

const UserInfo = () => {
  // API variables
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const livestreamerUserObj = useSelector(
    (state) => state.livestreaming.livestreamerUserObj
  );
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerIdInt = useSelector(
    (state) => state.livestreaming.livestreamerIdInt
  );
  const livestreamerProfilePhoto = useSelector(
    (state) => state.livestreaming.livestreamerProfilePhoto
  );
  const livestreamerProfileDecorations = useSelector(
    (state) => state.livestreaming.livestreamerProfileDecorations
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const livestreamerLevellingBadge = useSelector(
    (state) => state.livestreaming.livestreamerLevellingBadge
  );
  const livestreamerFollowers = useSelector(
    (state) => state.livestreaming.livestreamerFollowers
  );
  const livestreamerViewers = useSelector(
    (state) => state.livestreaming.livestreamerViewers
  );
  const livestreamerDiamonds = useSelector(
    (state) => state.livestreaming.livestreamerDiamonds
  );
  const livestreamerIsFollowing = useSelector(
    (state) => state.livestreaming.livestreamerIsFollowing
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Follow user API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      if (postFollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(true));
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | Unfollow user API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      if (postUnfollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(false));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onViewProfileCard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D6-6.1-Button",
      },
    });

    dispatch(updateId(livestreamerUserObj?.id));
    dispatch(updateIdInt(livestreamerUserObj?.id_int));
    dispatch(updateUsername(livestreamerUserObj?.username));
    dispatch(updateAge(livestreamerUserObj?.appearance?.age));
    dispatch(updateLocation(livestreamerUserObj?.location?.city));
    dispatch(updateMembershipType(livestreamerUserObj?.membership_type));
    dispatch(updateVerifiedProfile(livestreamerUserObj?.is_verified_profile));
    dispatch(updateVerifiedCaller(livestreamerUserObj?.verified_caller));
    dispatch(
      updateLevellingBadge(livestreamerUserObj?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        livestreamerUserObj?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(updateSpecialBadges(livestreamerUserObj?.special_badges));
    dispatch(
      updateProfilePhoto(livestreamerUserObj?.profile_photo?.original_photo)
    );
    dispatch(
      updateProfileDecorations(livestreamerUserObj?.profile_decorations)
    );
    dispatch(
      updatePublicPhotos(
        livestreamerUserObj?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        livestreamerUserObj?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
            isSensitive: photo?.is_sensitive,
          };
        })
      )
    );
    dispatch(updateTorTags(livestreamerUserObj?.tor_tags));
    dispatch(
      updateProfileAchievementBadge(
        livestreamerUserObj?.live_streaming_achievements?.profile_badges
      )
    );
    dispatch(updateAboutMeManualText(livestreamerUserObj?.aboutme));
    dispatch(updateAboutMeReadStatus(livestreamerUserObj?.aboutme_read_status));
    dispatch(updateLookingFor(livestreamerUserObj?.lookingfor));
    dispatch(
      updateAnnualIncome(livestreamerUserObj?.occupation?.annual_income)
    );
    dispatch(updateNetworth(livestreamerUserObj?.occupation?.net_worth));
    dispatch(updateOccupation(livestreamerUserObj?.occupation?.occupation));
    dispatch(
      updateLifestyle(livestreamerUserObj?.occupation?.lifestyle_budget)
    );
    dispatch(updateEducation(livestreamerUserObj?.occupation?.education));
    dispatch(
      updateRelationship(livestreamerUserObj?.relationship?.relationship_status)
    );
    dispatch(updateSmoking(livestreamerUserObj?.habit?.smoking));
    dispatch(updateDrinking(livestreamerUserObj?.habit?.drinking));
    dispatch(updateHeight(livestreamerUserObj?.appearance?.height));
    dispatch(updateBodyType(livestreamerUserObj?.appearance?.body_type));
    dispatch(updateEthnicity(livestreamerUserObj?.appearance?.ethnicity));
    dispatch(updateEyeColor(livestreamerUserObj?.appearance?.eye_color));
    dispatch(updateHairColor(livestreamerUserObj?.appearance?.hair_color));
    dispatch(updateChildren(livestreamerUserObj?.relationship?.children));
    dispatch(
      updateShowLastActive(livestreamerUserObj?.activity?.activity_display)
    );
    dispatch(
      updateLastActive(livestreamerUserObj?.activity?.last_seen_timestamp)
    );
    dispatch(
      updateShowMemberSince(livestreamerUserObj?.activity?.join_date_display)
    );
    dispatch(
      updateMemberSince(livestreamerUserObj?.activity?.joined_timestamp)
    );
    // About Me Functions
    const personalityType = livestreamerUserObj?.personality?.personality_type;
    const hobbies = livestreamerUserObj?.habit?.hobbies;
    const fitnessLevel = livestreamerUserObj?.habit?.fitness_level;
    const starSign = livestreamerUserObj?.personality?.star_sign;
    const dominantOrSubmissive =
      livestreamerUserObj?.personality?.dominant_or_submissive;
    const personalityTraits =
      livestreamerUserObj?.personality?.personality_traits;

    let tempAboutMeList = [];

    tempAboutMeList?.push({
      name: "personality_type",
      data: personalityType?.length > 0 ? personalityType : null,
    });
    tempAboutMeList?.push({
      name: "hobbies",
      data: hobbies?.length > 0 ? hobbies : null,
    });
    tempAboutMeList?.push({
      name: "star_sign",
      data: starSign,
    });
    tempAboutMeList?.push({
      name: "fitness_level",
      data: fitnessLevel,
    });
    tempAboutMeList?.push({
      name: "dominant_or_submissive",
      data: dominantOrSubmissive,
    });
    tempAboutMeList?.push({
      name: "personality_traits",
      data: personalityTraits,
    });
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));

    dispatch(updateAboutMe(tempAboutMeList));

    // Looking For Functions
    dispatch(
      updateLookingForAgeRange({
        min: livestreamerUserObj?.looking_fors?.age?.min,
        max: livestreamerUserObj?.looking_fors?.age?.max,
      })
    );
    dispatch(
      updateLookingForLocation(livestreamerUserObj?.looking_fors?.locations)
    );

    const temp = livestreamerUserObj?.looking_fors?.ethnicity?.map((item) => ({
      ethnicity: {
        id: item?.option?.id,
        locale: item?.option?.locale,
        display_locale: item?.option?.locale,
      },
      race: item?.sub_option
        ? {
            id: item?.sub_option?.id,
            locale: item?.sub_option?.locale,
            display_locale: item?.sub_option?.locale,
          }
        : null,
    }));
    dispatch(updateLookingForEthnicityRace(temp));
    dispatch(updateShouldCallApi(true));
    dispatch(
      updatePrivatePhotoRequested(
        livestreamerUserObj?.interaction?.private_photo_requested
      )
    );
    dispatch(
      updatePrivatePhotoApproval(
        livestreamerUserObj?.interaction?.private_photo_approval
      )
    );
    dispatch(
      updateRequestedMyPrivatePhoto(
        livestreamerUserObj?.interaction?.requested_my_private_photo
      )
    );
    dispatch(
      updateApprovedMyPrivatePhoto(
        livestreamerUserObj?.interaction?.approved_my_private_photo
      )
    );
    dispatch(
      updateIsBlocked(
        livestreamerUserObj?.interaction?.is_blocked ? true : false
      )
    );
    dispatch(
      updateIsSilentInteraction(
        livestreamerUserObj?.interaction?.should_silent_interactions
      )
    );
    dispatch(updateIsFavorited(livestreamerUserObj?.interaction?.is_favorited));
    dispatch(
      updateIsCallEnabled(
        livestreamerUserObj?.private_call_settings?.enable_calls &&
          livestreamerUserObj?.private_call_settings
            ?.system_private_calls_enabled
      )
    );
    dispatch(
      updateIsSugarbaby(
        livestreamerUserObj?.role?.toUpperCase() ===
          userConst.userRole.sugarbaby ||
          livestreamerUserObj?.role?.toUpperCase() ===
            userConst.userRole.maleSugarbaby
      )
    );
    dispatch(updateRole(livestreamerUserObj?.role));

    dispatch(updateUserProfileDialog(true));
  };
  const onFollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D6-6.2-Button",
      },
    });

    const obj = {
      user_id: livestreamerId,
    };

    postFollowUser(obj);
  };
  const onUnfollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D6-6.3-Button",
      },
    });

    const obj = {
      user_id: livestreamerId,
    };

    postUnfollowUser(obj);
  };

  // Utility Functions
  const onGetDiamonds = (diamonds) => {
    if (diamonds >= 1000) {
      return `${(diamonds / 1000).toFixed(0)}K`;
    } else if (diamonds >= 1000000) {
      return `${(diamonds / 1000000).toFixed(0)}M`;
    } else if (diamonds >= 1000000000) {
      return `${(diamonds / 1000000000).toFixed(0)}B`;
    } else {
      return diamonds;
    }
  };

  return (
    <div id="livestream-profile-view-dialog-user-info-subcomponent">
      <div className="padding-container">
        <div className="user-info-container">
          <div className="profile-picture-container">
            <CustomAvatar
              className="profile-picture"
              src={livestreamerProfilePhoto}
              role={userConst.userRole.sugarbaby}
              profileBorder={
                livestreamerUserObj?.profile_decorations?.profile_border_url
              }
              onClick={onViewProfileCard}
            />

            {livestreamerLevellingBadge && (
              <div className="levelling-badge-container">
                <img
                  className="levelling-badge"
                  src={livestreamerLevellingBadge}
                  alt="levellling-badge"
                />
              </div>
            )}
          </div>

          <div className="user-details-container">
            <div className="top-container">
              <div className="username-container">
                {livestreamerUserObj?.live_streaming_achievements
                  ?.chat_badges_set?.length > 0 && (
                  <div className="achievement-container">
                    {livestreamerUserObj?.live_streaming_achievements?.chat_badges_set?.map(
                      (badge, index) => (
                        <img
                          className="livestreamer-badge"
                          src={badge?.url}
                          key={index}
                        />
                      )
                    )}
                  </div>
                )}
                <div className="username">{livestreamerUsername || "-"}</div>
              </div>

              {!livestreamerIsFollowing && (
                <ButtonBase
                  className="follow-button"
                  component="div"
                  onClick={onFollowUser}
                >
                  {postFollowUserLoading ? (
                    <Spinner size={12} isPadding={false} />
                  ) : (
                    t("common.follow")
                  )}
                </ButtonBase>
              )}

              {livestreamerIsFollowing && (
                <ButtonBase
                  className="unfollow-button"
                  component="div"
                  onClick={onUnfollowUser}
                >
                  {postUnfollowUserLoading ? (
                    <Spinner
                      size={12}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("common.unfollow")
                  )}
                </ButtonBase>
              )}
            </div>

            <div className="user-stats-container">
              <div className="follower-container">
                <div className="followers-count">
                  {livestreamerFollowers || 0}
                </div>
                <div className="followers-label">{t("live.followers")}</div>
              </div>

              <Divider
                className="vertical-divider"
                orientation="vertical"
                flexItem
              />

              <div className="viewer-container">
                <div className="viewers-count">{livestreamerViewers || 0}</div>
                <div className="viewers0label">{t("live.viewers")}</div>
              </div>

              <Divider
                className="vertical-divider"
                orientation="vertical"
                flexItem
              />

              <div className="diamonds-container">
                <div className="diamonds-count">
                  {onGetDiamonds(livestreamerDiamonds) || 0}
                </div>
                <div className="diamonds-label">{t("live.diamonds")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
