// General
import "./overview.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCompletionQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetUserAioQuery,
  useLazyGetStreamerCenterQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateOnboardingSugarbookVerifiedDialog,
  updateUserProfileDialog,
} from "../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateDatingStyles,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateStarSign,
  updateFitnessLevel,
  updateDominantOrSubmissive,
  updatePersonalityTraits,
  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,
  // User Utility
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateIsSilentInteraction,
  updateIsSugarbaby,
  updateRole,
} from "../../../redux/store/userCardStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../redux/store/profileStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { ButtonBase } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import useCompactNumbers from "../../utility/custom-hooks/useCompactNumbers-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import ModerationStatus from "../edit/moderation-status/moderation-status";
import Banner from "../../shared/elements/banner/banner";
import ProfileInfo from "./profile-info/profile-info";
import ProfileStats from "./profile-stats/profile-stats";

const Overview = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getCompactNumbers = useCompactNumbers();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11",
      },
    });

    getProfile(null, false); // TODO: Change back to true after TW testing
    getProfileCompletion(null, false);
    getProfileCheck(null, false);
    getUserAio(null, true);
    getStreamerCenter(null, false);
    getCoins(null, false);
  }, []);

  // Lifecycle | Check for update | getProfile API Response
  useEffect(() => {
    if (getProfileCompletionSuccess) {
      dispatch(
        updateProfileCompletionPercentage(
          getProfileCompletionData?.data?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          getProfileCompletionData?.data?.profile_progress?.summary?.requirement
        )
      );
    }
  }, [getProfileCompletionSuccess]);

  // Event Handlers | Button
  const onViewOwnProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.1-Button",
      },
    });

    // User View
    dispatch(updateId(getProfileData?.data?.id));
    dispatch(updateIdInt(getProfileData?.data?.id_int));
    dispatch(updateUsername(getProfileData?.data?.username));
    dispatch(updateAge(getProfileData?.data?.appearance?.age));
    dispatch(updateGender(getProfileData?.data?.gender));
    dispatch(updateLocation(getProfileData?.data?.location?.city));
    dispatch(updateMembershipType(getProfileData?.data?.membership_type));
    dispatch(updateVerifiedProfile(getProfileData?.data?.is_verified_profile));
    dispatch(updateVerifiedCaller(getProfileData?.data?.verified_caller));
    dispatch(
      updateLevellingBadge(getProfileData?.data?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(updateSpecialBadges(getProfileData?.data?.special_badges));
    dispatch(
      updateProfilePhoto(getProfileData?.data?.profile_photo?.original_photo)
    );
    dispatch(
      updatePublicPhotos(
        getProfileData?.data?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        getProfileData?.data?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(updateTorTags(getProfileData?.data?.tor_tags));
    dispatch(
      updateDatingStyles(getProfileData?.data?.relationship?.dating_style)
    );
    dispatch(
      updateProfileAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.profile_badges
      )
    );
    // dispatch(updateAboutMe(getProfileData?.data?.aboutme));
    dispatch(updateLookingFor(getProfileData?.data?.interest));
    dispatch(updateOccupation(getProfileData?.data?.occupation?.occupation));
    dispatch(
      updateLifestyle(getProfileData?.data?.occupation?.lifestyle_budget)
    );
    dispatch(updateEducation(getProfileData?.data?.occupation?.education));
    dispatch(
      updateRelationship(
        getProfileData?.data?.relationship?.relationship_status
      )
    );
    dispatch(updateSmoking(getProfileData?.data?.habit?.smoking));
    dispatch(updateDrinking(getProfileData?.data?.habit?.drinking));
    dispatch(updateHeight(getProfileData?.data?.appearance?.height));
    dispatch(updateBodyType(getProfileData?.data?.appearance?.body_type));
    dispatch(updateEthnicity(getProfileData?.data?.appearance?.ethnicity));
    dispatch(updateEyeColor(getProfileData?.data?.appearance?.eye_color));
    dispatch(updateHairColor(getProfileData?.data?.appearance?.hair_color));
    dispatch(updateChildren(getProfileData?.data?.relationship?.children));

    const personalityType = getProfileData?.data?.personality?.personality_type;
    const hobbies = getProfileData?.data?.habit?.hobbies;
    const starSign = getProfileData?.data?.personality?.star_sign;
    const fitnessLevel = getProfileData?.data?.habit?.fitness_level;
    const dominantOrSubmissive =
      getProfileData?.data?.personality?.dominant_or_submissive;
    const personalityTraits =
      getProfileData?.data?.personality?.personality_traits;

    let tempAboutMeList = [];

    tempAboutMeList?.push({
      name: "personality_type",
      data: personalityType?.length > 0 ? personalityType : null,
    });
    tempAboutMeList?.push({
      name: "hobbies",
      data: hobbies?.lengtj > 0 ? hobbies : null,
    });
    tempAboutMeList?.push({
      name: "star_sign",
      data: starSign,
    });
    tempAboutMeList?.push({
      name: "fitness_level",
      data: fitnessLevel,
    });
    tempAboutMeList?.push({
      name: "dominant_or_submissive",
      data: dominantOrSubmissive,
    });
    tempAboutMeList?.push({
      name: "personality_traits",
      data: personalityTraits,
    });
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));

    dispatch(updateAboutMe(tempAboutMeList));

    dispatch(
      updateLookingForAgeRange({
        min: getProfileData?.data?.looking_fors?.age?.min,
        max: getProfileData?.data?.looking_fors?.age?.max,
      })
    );
    dispatch(
      updateLookingForLocation(getProfileData?.data?.looking_fors?.locations)
    );
    const temp = getProfileData?.data?.looking_fors?.ethnicity?.map((item) => ({
      ethnicity: {
        id: item?.option?.id,
        locale: item?.option?.locale,
        display_locale: item?.option?.locale,
      },
      race: item?.sub_option
        ? {
            id: item?.sub_option?.id,
            locale: item?.sub_option?.locale,
            display_locale: item?.sub_option?.locale,
          }
        : null,
    }));
    dispatch(updateLookingForEthnicityRace(temp));
    // User Utility
    dispatch(updatePrivatePhotoRequested(true));
    dispatch(updatePrivatePhotoApproval(true));
    dispatch(updateIsSilentInteraction(true));
    dispatch(updateIsSugarbaby(!isDaddy));
    dispatch(updateRole(getProfileData?.data?.role));

    // dispatch(updateUserProfileDialog(true)); // Dialog
    onNavigate(
      `${routeConst.profile.view.clearPath}${getProfileData?.data?.id}`
    ); // Page
  };
  const onNavigateToUpgrade = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.6-Button",
      },
    });

    onNavigate(routeConst.upgrade.path);
  };
  const onOpenSugarbookVerifiedDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.7-Button",
      },
    });

    dispatch(updateOnboardingSugarbookVerifiedDialog(true));
  };
  const onNavigateToBlogs = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.2-Button",
      },
    });

    window.open(getUserAioData?.data?.urls?.blog_link, "_blank");
  };
  const onNavigateToEvents = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.3-Button",
      },
    });

    window.open(routeConst.events.path, "_blank");
  };

  // Utility Functions
  const isFacialVerificationRequired = () => {
    return (
      !getProfileData?.data?.verifications?.facial?.verified &&
      getProfileData?.data?.verifications?.facial?.verification_display
    );
  };
  const onTestGtmTag = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Test-Tag-nested",
      },
    });

    window.dataLayer.push({
      event: "PWA-Test-Tag-window",
    });
  };
  const onRefreshApp = () => {
    window.location.reload(true);
  };
  const onRemoveRestartJourney = () => {
    sessionService?.deleteRestartJourneyDialogLastShown();
  };

  // Render Functions
  const renderUpgradeButton = () => {
    return (
      <ButtonBase
        className="button-container upgrade-now-button"
        component="div"
        onClick={onNavigateToUpgrade}
      >
        <div className="button-label upgrade-now-label">
          {getIcon("premiumStarIcon", "premium-star-icon")}
          {t("registration.premium_benefits.upgrade_premium")}
        </div>
        <div className="suffix upgrade-now-icon-color">
          <NavigateNextIcon />
        </div>
      </ButtonBase>
    );
  };
  const renderVerifiedButton = () => {
    return (
      <ButtonBase
        className="button-container sugarbook-verified-button"
        component="div"
        onClick={onOpenSugarbookVerifiedDialog}
      >
        <div className="button-label sugarbook-verified-label">
          {t("verification.get_sugarbook_verified")}
          {getIcon("verifiedProfileBadge", "navbar-verified-profile-badge")}
        </div>
        <div className="suffix sugarbook-verified-icon-color">
          <NavigateNextIcon />
        </div>
      </ButtonBase>
    );
  };
  const renderButtons = () => {
    if (isDaddy) {
      if (
        getProfileData?.data?.membership_type === userConst.membershipType.free
      ) {
        return renderUpgradeButton();
      } else if (isFacialVerificationRequired()) {
        return renderVerifiedButton();
      }
    } else {
      if (isFacialVerificationRequired()) {
        return renderVerifiedButton();
      } else if (
        getProfileData?.data?.membership_type === userConst.membershipType.free
      ) {
        return renderUpgradeButton();
      }
    }
    return null;
  };

  return (
    <div id="overview-page">
      <div className="padding-container">
        {/* In Review */}
        <div className="moderation-container">
          <ModerationStatus
            status={userConst.profileGeneralApprovalStatus.inReview}
            width={true}
          />

          {/* Rejected */}
          <ModerationStatus
            status={userConst.profileGeneralApprovalStatus.rejected}
            width={true}
          />
        </div>

        <ProfileInfo />

        <ProfileStats />

        <Banner type={"profile-overview"} view={"carousel"} />

        <div className="buttons-list-container">
          {renderButtons()}

          {/* Edit Profile */}
          <ButtonBase
            className="button-container"
            component="div"
            onClick={() => onNavigate(routeConst.profile.edit.path)}
          >
            <div className="button-label">
              {profileCompletionPercentage < 100
                ? t("profile.complete_profile")
                : t("profile.edit_profile")}
            </div>
            <div className="suffix">
              {profileCompletionPercentage < 100 && (
                <div className="suffix-label">
                  {profileCompletionPercentage || 0}%
                </div>
              )}
              <NavigateNextIcon />
            </div>
          </ButtonBase>

          {/* My Diamond */}
          {getUserAioData?.data?.modules?.my_diamonds_enabled && !isDaddy && (
            <ButtonBase
              className="button-container"
              component="div"
              onClick={() => onNavigate(routeConst.profile.streamerCenter.path)}
            >
              <div className="button-label">{t("profile.streamer_centre")}</div>
              <div className="suffix">
                {getIcon("sbDiamondIcon", "diamond-icon")}
                <div className="suffix-label">
                  {getCompactNumbers(
                    getStreamerCenterData?.data?.diamonds?.balance || 0
                  ) || 0}
                </div>
                <NavigateNextIcon />
              </div>
            </ButtonBase>
          )}

          {/* Wallet */}
          {getUserAioData?.data?.modules?.wallet_enabled && (
            <ButtonBase
              className="button-container"
              component="div"
              onClick={() => onNavigate(routeConst.profile.wallet.path)}
            >
              <div className="button-label">{t("profile.wallet")}</div>
              <div className="suffix">
                {getIcon("sbCoinIcon", "live-coin")}
                <div className="suffix-label">
                  {getCoinsData?.data?.balance?.toLocaleString() || 0}
                </div>
                <NavigateNextIcon />
              </div>
            </ButtonBase>
          )}

          {/* Video Call Settings */}
          {getUserAioData?.data?.modules?.private_calls_enabled && (
            <ButtonBase
              className="button-container"
              component="div"
              onClick={() => onNavigate(routeConst.settings.videoCall.path)}
            >
              <div className="button-label">
                {t("profile.1on1_video_call_settings")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </ButtonBase>
          )}

          {/* Levelling Center */}
          {getUserAioData?.data?.modules?.levelling_enabled && (
            <ButtonBase
              className="button-container"
              component="div"
              onClick={() => onNavigate(routeConst.profile.leveling.path)}
            >
              <div className="button-label">
                {t("profile.levelling_centre")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </ButtonBase>
          )}

          {/* Support */}
          <ButtonBase
            className="button-container"
            component="div"
            onClick={() => onNavigate(routeConst.support.path)}
          >
            <div className="button-label">{t("profile.support")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </ButtonBase>

          {/* FAQ */}
          {getUserAioData?.data?.modules?.faq_page_enabled && (
            <a
              className="button-container"
              href={routeConst.zendesk.path}
              target="_blank"
            >
              <div className="button-label">{t("profile.faq")}</div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </a>
          )}

          {/* Blogs */}
          {getUserAioData?.data?.modules?.blogs_page_enabled && (
            <ButtonBase
              className="button-container"
              component="div"
              onClick={onNavigateToBlogs}
            >
              <div className="button-label">{t("profile.blogs")}</div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </ButtonBase>
          )}

          {/* Events */}
          {getUserAioData?.data?.modules?.events_page_enabled && (
            <ButtonBase
              className="button-container"
              component="div"
              onClick={onNavigateToEvents}
            >
              <div className="button-label">{t("profile.events")}</div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </ButtonBase>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
