// General
import "./notification-permission-prompt-dialog.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateNotificationPermissionPromptDialog } from "../../../../redux/store/dialogStore";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../../redux/store/toastStore";
// Material UI
import { Dialog, ButtonBase } from "@mui/material";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// i18next
import { useTranslation } from "react-i18next";
// Firebase
import { getToken } from "firebase/messaging";
import { messaging } from "../../../../firebase";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const NotificationPermissionPromptDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const notificationPermissionPromptDialog = useSelector(
    (state) => state.dialog.notificationPermissionPromptDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (notificationPermissionPromptDialog) {
      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-NotificationPermissionPrompt-Dialog",
        },
      });
    }
  }, [notificationPermissionPromptDialog]);

  // Event Handlers | Button
  const onPromptPermission = () => {
    checkNotificationPermission();

    // Send to GTM
    TagManager.dataLayer({
      dataLayer: {
        event:
          "PWA-NotificationPermissionPrompt-Dialog-PromptPermission-Button",
      },
    });
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateNotificationPermissionPromptDialog(false));
  };

  // Utility Functions
  const checkNotificationPermission = () => {
    Notification.requestPermission()
      .then((response) => {
        switch (response) {
          case "granted":
            const successToastObj = {
              message: t("push_notification.notification_permission_granted"),
              autoClose: 5000,
            };
            dispatch(updateSuccessToast(successToastObj));

            getToken(messaging, {
              vapidKey:
                "BIBMuzxAc9Qtu3vTJIVc44tJuWMlO9vcQ2ZMNZEw48B5UomdpE3egYzaJCta5YC5pTFaw5qPDYgXyAaOnwyezxY",
            })
              .then((currentToken) => {
                if (currentToken) {
                  getUserAio(`?firebase_token=${currentToken}`, false);
                  sessionService.setNotificationToken(currentToken);

                  // Send to Sentry
                  Sentry.captureMessage(
                    `PWA-NotificationPermissionPrompt-Dialog-GetToken-Success`
                  );

                  // Send to GTM
                  TagManager.dataLayer({
                    dataLayer: {
                      event:
                        "PWA-NotificationPermissionPrompt-Dialog-GetToken-Success",
                    },
                  });

                  onCloseDialog();
                } else {
                  // Failed to generate the app registration token

                  // Send to Sentry
                  Sentry.captureMessage(
                    `PWA-NotificationPermissionPrompt-Dialog-GetToken-NoToken`
                  );

                  // Send to GTM
                  TagManager.dataLayer({
                    dataLayer: {
                      event:
                        "PWA-NotificationPermissionPrompt-Dialog-GetToken-NoToken",
                    },
                  });

                  onCloseDialog();
                }
              })
              .catch((err) => {
                console.log(
                  "An error occurred when requesting to receive the token.",
                  err
                );

                const error =
                  "AbortError: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker";

                // Send to Sentry
                Sentry.captureMessage(
                  `PWA-NotificationPermissionPrompt-Dialog-GetToken-Error-${err?.toString()}`
                );

                // Send to GTM
                TagManager.dataLayer({
                  dataLayer: {
                    event:
                      "PWA-NotificationPermissionPrompt-Dialog-GetToken-Error",
                  },
                });

                if (err.toString() === error) {
                  onGetToken();
                } else {
                  onCloseDialog();
                }
              });
            break;
          case "denied":
            const errorToastObj = {
              message: t("push_notification.notification_permission_denied"),
              autoClose: 5000,
            };
            dispatch(updateErrorToast(errorToastObj));

            // Send to Sentry
            Sentry.captureMessage(
              "PWA-NotificationPermissionPrompt-Dialog-PermissionDenied"
            );

            // Send to GTM
            TagManager.dataLayer({
              dataLayer: {
                event:
                  "PWA-NotificationPermissionPrompt-Dialog-PermissionDenied",
              },
            });

            onCloseDialog();
            break;
          case "default":
            onCloseDialog();
            break;
        }
      })
      .catch((error) => {
        // Notification Error
        console.log("Notification Error:", error);

        // Send to Sentry
        Sentry.captureMessage(
          `PWA-NotificationPermissionPrompt-Dialog-Permission-Error-${error?.toString()}`
        );

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-NotificationPermissionPrompt-Dialog-Permission-Error",
          },
        });
      });
  };
  const onGetToken = () => {
    getToken(messaging, {
      vapidKey:
        "BOlkSkrOScyL4MIOOp5fxsgtOsIN-ZmIqNF0DxuqVlzH4Vf4j4l7MpX_W86AglPimyVBsQiRwBxWKZqEPP7hfLk",
    })
      .then((currentToken) => {
        if (currentToken) {
          sessionService.setNotificationToken(currentToken);

          // Send to Sentry
          Sentry.captureMessage(
            `PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-Success`
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-Success",
            },
          });

          onCloseDialog();
        } else {
          // Failed to generate the app registration token

          // Send to Sentry
          Sentry.captureMessage(
            `PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-NoToken`
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-NoToken",
            },
          });

          onCloseDialog();
        }
      })
      .catch((err) => {
        console.log(
          "An error occurred when requesting to receive the token.",
          err
        );

        // Send to Sentry
        Sentry.captureMessage(
          `PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-NoToken`
        );

        // Send to GTM
        TagManager.dataLayer({
          dataLayer: {
            event:
              "PWA-NotificationPermissionPrompt-Dialog-GetToken-Retry-NoToken",
          },
        });

        onCloseDialog();
      });
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={notificationPermissionPromptDialog}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="notification-permission-prompt-dialog">
        <div className="dialog-header">
          <div className="close-button-container" onClick={onCloseDialog}>
            {getIcon("closeIcon", "close-button")}
          </div>
        </div>

        <div className="padding-container">
          <div className="notification-permission-icon-container">
            {getIcon(
              "notificationPermissionIcon",
              "notification-permission-icon"
            )}
          </div>

          <div className="geolocation-prompt-title">
            {t("push_notification.enable_notifications")}
          </div>
          <div className="geolocation-prompt-desc">
            {t("push_notification.enable_notifications_desc")}
          </div>

          <div className="buttons-container">
            <ButtonBase
              className="allow-button"
              component="div"
              onClick={onPromptPermission}
            >
              {t("common.allow")}
            </ButtonBase>
          </div>

          <div className="notification-note">
            {t("push_notification.enable_notifications_note")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NotificationPermissionPromptDialog;
