// General
import "./search-filter-dialog.scss";
import { useEffect, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
// Static Data
import searchConst from "../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSearchFilterDialog,
  updateDialogSearchParam,
} from "../../../../redux/store/dialogStore";
import {
  composeSearchBody,
  updateSelectedTorAfterApply,
  resetSelectedTorAfterApply,
} from "../../../../redux/store/searchStore";
import { updateIsFiltered } from "../../../../redux/store/searchFilterStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Divider, ButtonBase } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import TopBar from "./top-bar/top-bar";
import SearchInput from "./search-input/search-input";
import Location from "./location/location";
import TorFilter from "../../elements/filters/tor-filter/tor-filter";
import BasicFilters from "./basic-filters/basic-filters";
import AgeRange from "./age-range/age-range";
import HeightRange from "./height-range/height-range";
import AdvancedFilters from "./advanced-filters/advanced-filters";

const SearchFilterDialog = () => {
  // Redux variables
  const searchFilterDialog = useSelector(
    (state) => state.dialog.searchFilterDialog
  );
  const keyword = useSelector((state) => state.searchFilter.keyword);
  const location = useSelector((state) => state.searchFilter.location);
  const location_data = useSelector(
    (state) => state.searchFilter.location_data
  );
  const premiumOnly = useSelector((state) => state.searchFilter.premiumOnly);
  const photos = useSelector((state) => state.searchFilter.photos);
  const viewed = useSelector((state) => state.searchFilter.viewed);
  const unviewed = useSelector((state) => state.searchFilter.unviewed);
  const favorited = useSelector((state) => state.searchFilter.favorited);
  const favoritedMe = useSelector((state) => state.searchFilter.favoritedMe);
  const minAge = useSelector((state) => state.searchFilter.minAge);
  const maxAge = useSelector((state) => state.searchFilter.maxAge);
  const minHeight = useSelector((state) => state.searchFilter.minHeight);
  const maxHeight = useSelector((state) => state.searchFilter.maxHeight);
  const lifestyleData = useSelector(
    (state) => state.searchFilter.lifestyleData
  );
  const bodyTypeData = useSelector((state) => state.searchFilter.bodyTypeData);
  const ethnicityData = useSelector(
    (state) => state.searchFilter.ethnicityData
  );
  const eyeColorData = useSelector((state) => state.searchFilter.eyeColorData);
  const hairColorData = useSelector(
    (state) => state.searchFilter.hairColorData
  );
  const smokingData = useSelector((state) => state.searchFilter.smokingData);
  const drinkingData = useSelector((state) => state.searchFilter.drinkingData);
  const relationshipStatusData = useSelector(
    (state) => state.searchFilter.relationshipStatusData
  );
  const educationData = useSelector(
    (state) => state.searchFilter.educationData
  );
  const tor = useSelector((state) => state.searchFilter.tor);
  const dispatch = useDispatch();

  // Router variables
  const [searchParams, setSearchParams] = useSearchParams();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (searchFilterDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D21",
        },
      });

      dispatch(
        updateDialogSearchParam({
          key: "searchFilterDialog=true",
          value: true,
        })
      );
    } else {
      dispatch(
        updateDialogSearchParam({
          key: "searchFilterDialog=true",
          value: false,
        })
      );
    }
  }, [searchFilterDialog]);

  // Lifecycle | Check for update | searchParams
  useEffect(() => {
    if (searchParams?.get("searchFilterDialog") === "true") return;

    dispatch(updateSearchFilterDialog(false));
  }, [searchParams]);

  // Event Handlers | Button
  const onApplyFilter = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D21-21.1-Button",
      },
    });

    const obj = {
      keyword,
      location,
      location_data,
      premiumOnly,
      photos,
      viewed,
      unviewed,
      favorited,
      favoritedMe,
      minAge,
      maxAge,
      minHeight,
      maxHeight,
      lifestyle: lifestyleData,
      bodyType: bodyTypeData,
      ethnicity: ethnicityData,
      eyeColor: eyeColorData,
      hairColor: hairColorData,
      smoking: smokingData,
      drinking: drinkingData,
      relationshipStatus: relationshipStatusData,
      education: educationData,
      tor,
    };

    dispatch(composeSearchBody(obj));
    dispatch(updateIsFiltered());
    dispatch(updateSelectedTorAfterApply(tor));
    dispatch(updateSearchFilterDialog(false));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(resetSelectedTorAfterApply());
    dispatch(updateSearchFilterDialog(false));
  };

  // Utility Functions
  const onApplyFilterCallback = () => {
    onApplyFilter();
  };

  return (
    <Dialog
      className="custom-top-radius20-dialog custom-dialog-margin-top"
      fullScreen
      open={searchFilterDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="search-filter-dialog">
        <TopBar applyFilterCallback={onApplyFilterCallback} />

        <div className="body-container">
          <SearchInput />

          <Divider />

          <Location />

          <Divider />

          <TorFilter filtered={true} />

          <Divider />

          <BasicFilters />

          <Divider />

          <AgeRange type={searchConst.searchFilterType.dialog} />

          <Divider />

          <HeightRange type={searchConst.searchFilterType.dialog} />

          <AdvancedFilters />

          <Divider />
        </div>

        <ButtonBase
          className="apply-filter-button"
          component="div"
          onClick={onApplyFilter}
        >
          {t("filter.apply_filters")}
        </ButtonBase>
      </div>
    </Dialog>
  );
};

export default SearchFilterDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
