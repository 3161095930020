// General
import "./navbar-desktop-view.scss";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetUserAioQuery,
  useLazyGetBadgeCountQuery,
  useLazyGetStreamerCenterQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateInterestTab } from "../../../../../redux/store/interestStore";
import { updateLivestreamRefreshPassthrough } from "../../../../../redux/store/livestreamingStore";
import { updatePaymentTag } from "../../../../../redux/store/paymentStore";
import {
  updateOnboardingSugarbookVerifiedDialog,
  updateUserProfileDialog,
} from "../../../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateOccupation,
  updateDatingStyles,
  updatePosition,
  updateIndustry,
  updateDatingBudget,
  updateAnnualIncome,
  updateNetworth,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  updateIsLgbtqInterested,

  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateIsSelf,
  updateProfileDecorations,
} from "../../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Divider,
  Menu,
  MenuItem,
  Badge,
  ButtonBase,
  useMediaQuery,
} from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useCompactNumbers from "../../../../utility/custom-hooks/useCompactNumbers-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../spinner/spinner";
import CustomAvatar from "../../custom-avatar/custom-avatar";
import NotificationButton from "./notification-button/notification-button";
import NavbarQuickAction from "../navbar-quick-action/navbar-quick-action";
import NavbarCoin from "../navbar-coin/navbar-coin";

const NavbarDesktopView = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getRefreshProfile,
    {
      data: getRefreshProfileData,
      error: getRefreshProfileErrorData,
      isFetching: getRefreshProfileFetching,
      isLoading: getRefreshProfileLoading,
      isSuccess: getRefreshProfileSuccess,
      isError: getRefreshProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getBadgeCount,
    {
      data: getBadgeCountData,
      error: getBadgeCountErrorData,
      isFetching: getBadgeCountFetching,
      isLoading: getBadgeCountLoading,
      isSuccess: getBadgeCountSuccess,
      isError: getBadgeCountError,
    },
  ] = useLazyGetBadgeCountQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // General variables
  const [showNavbar, setShowNavbar] = useState(true);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const releaseChannel = useSelector((state) => state.public.releaseChannel);
  const showVideoCall = useSelector((state) => state.app.showVideoCall);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const dispatch = useDispatch();

  // MUI variables
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const openSettingsMenu = Boolean(anchorElSettings);
  const isTablet = useMediaQuery("(min-width: 960px)");
  const showLogo = useMediaQuery("(min-width: 860px)");

  // Router variables
  const location = useLocation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getCompactNumbers = useCompactNumbers();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getProfile(null, true);
    getProfileCheck(null, true);
    getUserAio(null, true);
    getBadgeCount("navbar", false);
    getStreamerCenter(null, false);
    getCoins(null, false);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | getRefreshProfile API Response
  useEffect(() => {
    if (getRefreshProfileFetching || getRefreshProfileLoading) {
    } else if (getRefreshProfileSuccess) {
      // User View
      dispatch(updateId(getRefreshProfileData?.data?.id));
      dispatch(updateIdInt(getRefreshProfileData?.data?.id_int));
      dispatch(updateUsername(getRefreshProfileData?.data?.username));
      dispatch(updateAge(getRefreshProfileData?.data?.appearance?.age));
      dispatch(updateGender(getRefreshProfileData?.data?.gender));
      dispatch(updateLocation(getRefreshProfileData?.data?.location?.city));
      dispatch(
        updateMembershipType(getRefreshProfileData?.data?.membership_type)
      );
      dispatch(
        updateVerifiedProfile(getRefreshProfileData?.data?.is_verified_profile)
      );
      dispatch(
        updateVerifiedCaller(getRefreshProfileData?.data?.verified_caller)
      );
      dispatch(
        updateLevellingBadge(
          getRefreshProfileData?.data?.levelling_profile?.badge
        )
      );
      dispatch(
        updateLivestreamingAchievementBadge(
          getRefreshProfileData?.data?.live_streaming_achievements
            ?.chat_badges_set
        )
      );
      dispatch(
        updateSpecialBadges(getRefreshProfileData?.data?.special_badges)
      );
      dispatch(
        updateProfilePhoto(
          getRefreshProfileData?.data?.profile_photo?.photo_status ===
            "REJECTED"
            ? null
            : getRefreshProfileData?.data?.profile_photo?.original_photo
        )
      );
      dispatch(
        updateProfileDecorations(
          getRefreshProfileData?.data?.profile_decorations
        )
      );
      dispatch(
        updatePublicPhotos(
          getRefreshProfileData?.data?.public_photos?.map((photo) => {
            return {
              photoUrl: photo?.original_photo,
            };
          })
        )
      );
      dispatch(
        updatePrivatePhotos(
          getRefreshProfileData?.data?.private_photos?.map((photo) => {
            return {
              photoUrl: photo?.original_photo,
              status: photo?.photo_status,
              thumbnailData: photo?.thumbnail_data,
            };
          })
        )
      );
      dispatch(updateTorTags(getRefreshProfileData?.data?.tor_tags));
      dispatch(
        updateDatingStyles(
          getRefreshProfileData?.data?.relationship?.dating_style
        )
      );
      dispatch(
        updateProfileAchievementBadge(
          getRefreshProfileData?.data?.live_streaming_achievements
            ?.profile_badges
        )
      );

      // About Me Functions
      const personalityType =
        getProfileData?.data?.personality?.personality_type;
      const hobbies = getProfileData?.data?.habit?.hobbies;
      const fitnessLevel = getProfileData?.data?.habit?.fitness_level;
      const starSign = getProfileData?.data?.personality?.star_sign;
      const dominantOrSubmissive =
        getProfileData?.data?.personality?.dominant_or_submissive;
      const personalityTraits =
        getProfileData?.data?.personality?.personality_traits;

      let tempAboutMeList = [];

      tempAboutMeList?.push({
        name: "personality_type",
        data: personalityType?.length > 0 ? personalityType : null,
      });
      tempAboutMeList?.push({
        name: "hobbies",
        data: hobbies?.length > 0 ? hobbies : null,
      });
      tempAboutMeList?.push({
        name: "star_sign",
        data: starSign,
      });
      tempAboutMeList?.push({
        name: "fitness_level",
        data: fitnessLevel,
      });
      tempAboutMeList?.push({
        name: "dominant_or_submissive",
        data: dominantOrSubmissive,
      });
      tempAboutMeList?.push({
        name: "personality_traits",
        data: personalityTraits,
      });

      dispatch(
        updatePersonalityType(
          getRefreshProfileData?.data?.personality?.personality_type
        )
      );
      dispatch(updateHobbies(getRefreshProfileData?.data?.habit?.hobbies));
      dispatch(
        updateFitnessLevel(getRefreshProfileData?.data?.habit?.fitness_level)
      );
      dispatch(
        updateStarSign(getRefreshProfileData?.data?.personality?.star_sign)
      );
      dispatch(
        updateDominantOrSubmissive(
          getRefreshProfileData?.data?.personality?.dominant_or_submissive
        )
      );
      dispatch(
        updatePersonalityTraits(
          getRefreshProfileData?.data?.personality?.personality_traits
        )
      );
      dispatch(updateAboutMe(tempAboutMeList));
      dispatch(updateAboutMeManualText(getRefreshProfileData?.data?.aboutme));
      dispatch(updateAboutMeReadStatus(null));

      dispatch(
        updatePosition(getRefreshProfileData?.data?.occupation?.position)
      );
      dispatch(
        updateIndustry(getRefreshProfileData?.data?.occupation?.industry)
      );
      dispatch(
        updateLifestyle(
          getRefreshProfileData?.data?.occupation?.lifestyle_budget
        )
      );
      dispatch(
        updateDatingBudget(
          getRefreshProfileData?.data?.relationship?.dating_budget
        )
      );
      dispatch(
        updateAnnualIncome(
          getRefreshProfileData?.data?.occupation?.annual_income
        )
      );
      dispatch(
        updateNetworth(getRefreshProfileData?.data?.occupation?.networth)
      );
      dispatch(
        updateEducation(getRefreshProfileData?.data?.occupation?.education)
      );
      dispatch(
        updateRelationship(
          getRefreshProfileData?.data?.relationship?.relationship_status
        )
      );
      dispatch(updateSmoking(getRefreshProfileData?.data?.habit?.smoking));
      dispatch(updateDrinking(getRefreshProfileData?.data?.habit?.drinking));
      dispatch(
        updateAlcoholPreference(getRefreshProfileData?.data?.habit?.alcohol)
      );
      dispatch(updateHeight(getRefreshProfileData?.data?.appearance?.height));
      dispatch(
        updateBodyType(getRefreshProfileData?.data?.appearance?.body_type)
      );
      dispatch(
        updateEthnicity(getRefreshProfileData?.data?.appearance?.ethnicity)
      );
      dispatch(
        updateEyeColor(getRefreshProfileData?.data?.appearance?.eye_color)
      );
      dispatch(
        updateHairColor(getRefreshProfileData?.data?.appearance?.hair_color)
      );
      dispatch(
        updateChildren(getRefreshProfileData?.data?.relationship?.children)
      );
      dispatch(
        updateIsLgbtqInterested(
          getRefreshProfileData?.data?.relationship?.is_lgbtq_interested
        )
      );

      // User Utility
      dispatch(updateShouldCallApi(false));
      dispatch(updatePrivatePhotoRequested(true));
      dispatch(updatePrivatePhotoApproval(true));
      dispatch(updateIsBlocked(false));
      dispatch(updateIsSilentInteraction(true));
      dispatch(updateIsFavorited(false));
      dispatch(updateIsCallEnabled(false));
      dispatch(
        updateIsSugarbaby(
          getRefreshProfileData?.data?.role?.toUpperCase() ===
            userConst.userRole.sugarbaby ||
            getRefreshProfileData?.data?.role?.toUpperCase() ===
              userConst.userRole.maleSugarbaby
        )
      );
      dispatch(updateRole(getRefreshProfileData?.data?.role));
      dispatch(updateIsSelf(true));
      dispatch(updateLookingFor(getRefreshProfileData?.data?.interest));

      // dispatch(updateUserProfileDialog(true)); // Dialog
      onNavigate(
        `${routeConst.profile.view.clearPath}${getRefreshProfileData?.data?.id}`
      );
    } else if (getRefreshProfileError) {
    }
  }, [
    getRefreshProfileFetching,
    getRefreshProfileLoading,
    getRefreshProfileSuccess,
    getRefreshProfileError,
  ]);

  // Lifecycle | Check for update | Hide navbar based on route
  useEffect(() => {
    if (
      location.pathname.includes(routeConst.usernameSignup.path) ||
      location.pathname.includes(routeConst.detailsSignup.path) ||
      location.pathname.includes(routeConst.premiumBenefits.path) ||
      location.pathname.includes(routeConst.videoCall.privateStandby.path) ||
      location.pathname.includes(routeConst.videoCall.room.path) ||
      location.pathname.includes(routeConst.verify.emailVerification.path) ||
      location.pathname.includes(routeConst.verify.emailVerification.altPath) ||
      location.pathname.includes(routeConst.verify.mobileVerification.path) ||
      location.pathname.includes(
        routeConst.verify.mobileVerification.altPath
      ) ||
      location.pathname.includes(routeConst.phoneAuthentication.path) ||
      location.pathname.includes(routeConst.phoneAuthentication.altPath) ||
      location.pathname.includes(routeConst.verify.sugarbookVerified.path)
    ) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location]);

  // Event Handlers | Button
  const onRedirect = (path) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-47-RedirectTo-${path}`,
      },
    });

    getBadgeCount("navbar", false);

    if (path === routeConst.interest.path) {
      dispatch(updateInterestTab(1));
    } else if (path === routeConst.live.path) {
      // Refresh livestream channels even if on the same page
      dispatch(updateLivestreamRefreshPassthrough());
    }
  };
  const onRedirectToLanding = () => {
    // check if domain has .my
    const isMyDomain =
      window.location.hostname.includes(".my") ||
      window.location.hostname.includes(".net");

    if (isMyDomain) {
      window.open("https://sugarbook.net", "_self");
    } else {
      window.open("https://sugarbook.com", "_self");
    }
  };
  const onRedirectToStreamerCenter = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.1-Button",
      },
    });
  };
  const onRedirectToWallet = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.2-Button",
      },
    });
  };
  const onRedirectToLeaderboard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.3-Button",
      },
    });
  };
  const onUpgrade = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.4-Button",
      },
    });

    dispatch(updatePaymentTag("pwa-navbar-desktop-upgrade-button"));
  };
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.5-Button",
      },
    });

    getRefreshProfile("?navbarDesktop=true", false);
  };
  const onNavigateToBlogs = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.6-Button",
      },
    });

    window.open(getUserAioData?.data?.urls?.blog_link, "_blank");
  };
  const onNavigateToEvents = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.7-Button",
      },
    });

    window.open(routeConst.events.path, "_blank");
  };
  const onLogout = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-47-47.8-Button",
      },
    });

    onNavigate(routeConst.logout.path);
  };

  // Event Handlers | MUI Menu
  const onOpenMenu = (state) => (event) => {
    switch (state) {
      case "chat":
        break;
      case "sugar":
        break;
      case "event":
        break;
      case "language":
        break;
      case "settings":
        setAnchorElSettings(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const onCloseMenu = (state) => {
    switch (state) {
      case "chat":
        break;
      case "sugar":
        break;
      case "event":
        break;
      case "language":
        break;
      case "settings":
        setAnchorElSettings(null);
        break;
      case "sugarbook-verified":
        dispatch(updateOnboardingSugarbookVerifiedDialog(true));
        setAnchorElSettings(null);
        break;
      default:
        setAnchorElSettings(null);
        break;
    }
  };

  if (showNavbar) {
    return (
      <div id="navbar-navbar-desktop-view-subcomponent">
        <div className="space-around">
          {isLoggedIn && (
            <div className="login-view">
              <div className="logo-navlink-container">
                {showLogo && (
                  <div
                    className="sugarbook-logo-container"
                    onClick={
                      isLoggedIn
                        ? () => onNavigate(routeConst.search.path)
                        : () => onNavigate(routeConst.landing.path)
                    }
                  >
                    {isTablet
                      ? getIcon("sugarbookLogo", "sugarbook-logo")
                      : getIcon("sugarbookIcon", "sugarbook-logo")}
                  </div>
                )}

                <div
                  className={`navigation-left-container ${
                    showLogo ? "default-gap" : "reduce-gap"
                  }`}
                >
                  <Link
                    className="link-container"
                    to={routeConst.search.path}
                    onClick={() => onRedirect(routeConst.search.path)}
                  >
                    <ButtonBase
                      component="div"
                      className="button-base-container"
                    >
                      <Badge
                        className="custom-badge"
                        badgeContent={0}
                        variant="dot"
                      >
                        <div className="link-logo-container">
                          {getIcon("searchDesktopIcon", "search-icon")}
                        </div>
                      </Badge>

                      <div className="link-label">{t("common.search")}</div>
                    </ButtonBase>
                  </Link>

                  {getUserAioData?.data?.modules?.messaging_enabled && (
                    <Link
                      className="link-container"
                      to={routeConst.inbox.path}
                      onClick={() => onRedirect(routeConst.inbox.path)}
                    >
                      <ButtonBase
                        component="div"
                        className="button-base-container"
                      >
                        <Badge
                          className="custom-badge search-badge"
                          badgeContent={
                            releaseChannel !==
                              utilityConst.releaseChannel.stable &&
                            getBadgeCountData?.data?.counts?.new_message
                              ? 1
                              : 0
                          }
                          variant="dot"
                        >
                          <div className="link-logo-container">
                            {getIcon("messageDesktopIcon", "message-icon")}
                          </div>
                        </Badge>

                        <div className="link-label">{t("inbox.messages")}</div>
                      </ButtonBase>
                    </Link>
                  )}

                  <Link
                    className="link-container"
                    to={routeConst.interest.path}
                    onClick={() => onRedirect(routeConst.interest.path)}
                  >
                    <ButtonBase
                      component="div"
                      className="button-base-container"
                    >
                      <Badge
                        className="custom-badge search-badge"
                        badgeContent={
                          releaseChannel !==
                            utilityConst.releaseChannel.stable &&
                          (getBadgeCountData?.data?.counts?.new_favorited_me ||
                            getBadgeCountData?.data?.counts?.new_viewed_me)
                            ? 1
                            : 0
                        }
                        variant="dot"
                      >
                        <div className="link-logo-container">
                          {getIcon("favoriteDesktopIcon", "favorite-icon")}
                        </div>
                      </Badge>

                      <div className="link-label">{t("profile.favorites")}</div>
                    </ButtonBase>
                  </Link>

                  {showVideoCall &&
                    getUserAioData?.data?.modules?.private_calls_enabled && (
                      <Link
                        className="link-container"
                        to={routeConst.videoCall.path}
                        onClick={() => onRedirect(routeConst.videoCall.path)}
                      >
                        <ButtonBase
                          component="div"
                          className="button-base-container"
                        >
                          <Badge
                            className="custom-badge search-badge"
                            badgeContent={0}
                            variant="dot"
                          >
                            <div className="link-logo-container">
                              {getIcon("videoCallDesktopIcon", "video-icon")}
                            </div>
                          </Badge>

                          <div className="link-label">
                            {t("1on1.video_call")}
                          </div>
                        </ButtonBase>
                      </Link>
                    )}

                  {(getUserAioData?.data?.modules?.live_streaming_enabled ||
                    getUserAioData?.data?.modules?.private_calls_enabled) && (
                    <Link
                      className="link-container"
                      to={routeConst.live.path}
                      onClick={() => onRedirect(routeConst.live.path)}
                    >
                      <ButtonBase
                        component="div"
                        className="button-base-container"
                      >
                        <Badge
                          className="custom-badge search-badge"
                          badgeContent={0}
                          variant="dot"
                        >
                          <div className="link-logo-container">
                            {getIcon("liveDesktopIcon", "live-icon")}
                          </div>
                        </Badge>

                        <div className="link-label">
                          {t("mini-profile-tabs.live")}
                        </div>
                      </ButtonBase>
                    </Link>
                  )}
                </div>
              </div>

              <div className="navigation-right-container">
                <div className="login-container">
                  <NotificationButton />

                  {isDaddy && getUserAioData?.data?.modules?.wallet_enabled && (
                    <Link
                      className="sb-coin-icon-container"
                      to={routeConst.profile.wallet.path}
                      onClick={onRedirectToWallet}
                    >
                      <NavbarCoin disableButton={true} />
                    </Link>
                  )}

                  {!isDaddy &&
                    getUserAioData?.data?.modules?.my_diamonds_enabled && (
                      <Link
                        className="diamond-icon-container"
                        to={routeConst.profile.streamerCenter.path}
                        onClick={onRedirectToStreamerCenter}
                      >
                        <ButtonBase
                          className="diamond-icon-button-container"
                          component="div"
                        >
                          {getIcon("sbDiamondIcon", "diamond-icon")}
                        </ButtonBase>
                      </Link>
                    )}

                  {(getUserAioData?.data?.modules
                    ?.private_calls_leaderboard_enabled ||
                    getUserAioData?.data?.modules
                      ?.live_streaming_leaderboard_enabled) && (
                    <Link
                      className="leaderboard-new-icon-container"
                      to={routeConst.leaderboard.path}
                      onClick={onRedirectToLeaderboard}
                    >
                      <ButtonBase
                        className="leaderboard-new-icon-button-container"
                        component="div"
                      >
                        {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                      </ButtonBase>
                    </Link>
                  )}

                  <NavbarQuickAction view={"desktop"} />

                  <div className="profile-container">
                    {getRefreshProfileFetching ? (
                      <Spinner size={24} />
                    ) : (
                      <div
                        className="profile-logo-container"
                        onClick={onViewProfile}
                      >
                        <CustomAvatar
                          className="profile-logo"
                          src={
                            getProfileData?.data?.profile_photo
                              ?.photo_status === "REJECTED"
                              ? null
                              : getProfileData?.data?.profile_photo
                                  ?.original_photo
                          }
                          role={getProfileData?.data?.role}
                          profileBorder={
                            getProfileData?.data?.profile_decorations
                              ?.profile_border_url
                          }
                        />
                      </div>
                    )}

                    <div
                      className="dropdown-logo-container"
                      onClick={onOpenMenu("settings")}
                    >
                      {getIcon("keyboardArrowDownIcon", "")}
                    </div>

                    <Menu
                      id="settings-menu"
                      anchorEl={anchorElSettings}
                      open={openSettingsMenu}
                      onClose={() => onCloseMenu()}
                    >
                      {!getProfileData?.data?.verifications?.facial?.verified &&
                        getProfileData?.data?.verifications?.facial
                          ?.verification_display && (
                          <MenuItem
                            onClick={() => {
                              onCloseMenu("sugarbook-verified");
                            }}
                          >
                            {t("verification.get_sugarbook_verified")}{" "}
                            {getIcon(
                              "verifiedProfileBadge",
                              "navbar-verified-profile-badge"
                            )}
                          </MenuItem>
                        )}

                      {!getProfileData?.data?.verifications?.facial?.verified &&
                        getProfileData?.data?.verifications?.facial
                          ?.verification_display && <Divider />}

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onNavigate(routeConst.profile.edit.path);
                        }}
                      >
                        <div className="menu-item-container">
                          <div className="menu-item-label">
                            {profileCompletionPercentage < 100
                              ? t("profile.complete_profile")
                              : t("profile.edit_profile")}
                          </div>

                          <div className="menu-item-suffix-container">
                            {profileCompletionPercentage < 100 && (
                              <div className="suffix-label">
                                {profileCompletionPercentage || 0}%
                              </div>
                            )}
                          </div>
                        </div>
                      </MenuItem>

                      <Divider />

                      {!isDaddy &&
                        getUserAioData?.data?.modules?.my_diamonds_enabled && (
                          <MenuItem
                            onClick={() => {
                              onCloseMenu("settings");
                              onNavigate(
                                routeConst.profile.streamerCenter.path
                              );
                            }}
                          >
                            <div className="menu-item-container">
                              <div className="menu-item-label">
                                {t("profile.streamer_centre")}
                              </div>
                              <div className="menu-item-suffix-container">
                                {getIcon("sbDiamondIcon", "diamond-icon")}
                                <div className="suffix-label">
                                  {getCompactNumbers(
                                    getStreamerCenterData?.data?.diamonds
                                      ?.balance || 0
                                  ) || 0}
                                </div>
                              </div>
                            </div>
                          </MenuItem>
                        )}

                      {!isDaddy &&
                        getUserAioData?.data?.modules?.my_diamonds_enabled && (
                          <Divider />
                        )}

                      {getUserAioData?.data?.modules?.wallet_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.profile.wallet.path);
                          }}
                        >
                          <div className="menu-item-container">
                            <div className="menu-item-label">
                              {t("profile.wallet")}
                            </div>
                            <div className="menu-item-suffix-container">
                              {getIcon("sbCoinIcon", "coin-icon")}
                              <div className="suffix-label">
                                {getCoinsData?.data?.balance?.toLocaleString() ||
                                  0}
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.wallet_enabled && (
                        <Divider />
                      )}

                      {getUserAioData?.data?.modules?.private_calls_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.settings.videoCall.path);
                          }}
                        >
                          {t("profile.1on1_video_call_settings")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.private_calls_enabled && (
                        <Divider />
                      )}

                      {getUserAioData?.data?.modules?.levelling_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.profile.leveling.path);
                          }}
                        >
                          {t("profile.levelling_centre")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.levelling_enabled && (
                        <Divider />
                      )}

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onNavigate(routeConst.support.path);
                        }}
                      >
                        {t("profile.support")}
                      </MenuItem>

                      <Divider />

                      <MenuItem>
                        <a
                          className="navbar-no-decoration"
                          href={routeConst.zendesk.path}
                          target="_blank"
                        >
                          {t("profile.faq")}
                        </a>
                      </MenuItem>

                      <Divider />

                      {getUserAioData?.data?.modules?.blogs_page_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigateToBlogs();
                          }}
                        >
                          {t("profile.blogs")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.blogs_page_enabled && (
                        <Divider />
                      )}

                      {getUserAioData?.data?.modules?.events_page_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigateToEvents();
                          }}
                        >
                          {t("profile.events")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.events_page_enabled && (
                        <Divider />
                      )}

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onNavigate(routeConst.settings.path);
                        }}
                      >
                        {t("profile.settings")}
                      </MenuItem>

                      <Divider />

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onLogout();
                        }}
                      >
                        {t("settings.log_out")}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isLoggedIn && (
            <div className="not-login-view">
              <div onClick={onRedirectToLanding}>
                <div className="sugarbook-logo-container">
                  {getIcon("sugarbookLogo", "sugarbook-logo")}
                </div>
              </div>

              <span className="spacer"></span>

              <div className="navigation-right-container">
                <div className="not-login-container">
                  <div
                    className="login-button-container"
                    onClick={() => onNavigate(routeConst.login.path)}
                  >
                    <div className="login-button">{t("login.login")}</div>
                  </div>
                  <div
                    className="join-button-container"
                    onClick={() => onNavigate(routeConst.join.path)}
                  >
                    <div className="join-button">{t("login.join")}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default NavbarDesktopView;
