// General
import "./upgrade.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetPwaPaymentsQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetPromotionsQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSpecialEvent } from "../../../redux/store/upgradeStore";
import { updateWarningToast } from "../../../redux/store/toastStore";
import { updateUpgradePromoDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { ButtonBase } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import Spinner from "../../shared/elements/spinner/spinner";
import UpgradePerks from "./upgrade-perks/upgrade-perks";
import UpgradePlans from "./upgrade-plans/upgrade-plans";
import UpgradePromoDialog from "../../shared/dialog-content/upgrade-promo-dialog/upgrade-promo-dialog";

const Upgrade = (props) => {
  const { showUtilityNavbar = false } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getPwaPayments,
    {
      data: getPwaPaymentsData,
      error: getPwaPaymentsErrorData,
      isFetching: getPwaPaymentsFetching,
      isLoading: getPwaPaymentsLoading,
      isSuccess: getPwaPaymentsSuccess,
      isError: getPwaPaymentsError,
    },
  ] = useLazyGetPwaPaymentsQuery();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    getPromotions,
    {
      data: getPromotionsData,
      error: getPromotionsErrorData,
      isFetching: getPromotionsFetching,
      isLoading: getPromotionsLoading,
      isSuccess: getPromotionsSuccess,
      isError: getPromotionsError,
    },
  ] = useLazyGetPromotionsQuery();

  // Redux variables
  const selectedUpgradePackage = useSelector(
    (state) => state.upgrade.selectedUpgradePackage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-53",
      },
    });

    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        if (
          getProfileData?.data?.membership_type ===
            userConst.membershipType.premium ||
          getProfileData?.data?.membership_type ===
            userConst.membershipType.freePremium ||
          getProfileData?.data?.membership_type ===
            userConst.membershipType.diamond
        ) {
          const toastObj = {
            message: "You are already a Premium member",
            autoClose: 5000,
          };
          dispatch(updateWarningToast(toastObj));

          onNavigate(routeConst.search.path);
        } else {
          getPwaPayments(null, false);
          getProfileCheck();
          getPromotions();
        }
      }
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Pwa Payments API Response
  useEffect(() => {
    if (getPwaPaymentsFetching || getPwaPaymentsLoading) {
    } else if (getPwaPaymentsSuccess) {
      if (getPwaPaymentsSuccess?.status === 0) {
        if (getPwaPaymentsData?.data?.special_event) {
          dispatch(updateSpecialEvent(getPwaPaymentsData?.data?.special_event));
        }
      }
    } else if (getPwaPaymentsError) {
    }
  }, [
    getPwaPaymentsFetching,
    getPwaPaymentsLoading,
    getPwaPaymentsSuccess,
    getPwaPaymentsError,
  ]);

  // Event Handlers | Button
  const onOpenPromoDialog = () => {
    dispatch(updateUpgradePromoDialog(true));
  };
  const onUpgrade = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-53-To-53.1-Button",
      },
    });

    onNavigate(routeConst.upgrade.checkout.path);
  };

  // Render Functions | API Response
  if (getPwaPaymentsFetching || getPwaPaymentsLoading) {
    return <Spinner />;
  } else if (getPwaPaymentsSuccess) {
    return (
      <div id="upgrade-page">
        {showUtilityNavbar && <UtilityDesktopNavbar leftButton={"back"} />}

        <UpgradePerks />

        <UpgradePlans />

        <div className="promo-code-container" onClick={onOpenPromoDialog}>
          <div className="price-tag-icon-container">
            {getIcon("priceTagIcon", "price-tag-icon")}
            {t("upgrade.enter_promo_code")}
          </div>
        </div>

        <ButtonBase
          className="continue-button"
          component="div"
          onClick={onUpgrade}
        >
          {t("common.continue")}
        </ButtonBase>

        <div className="billed-amount">
          {t("upgrade.billed_today", {
            amount:
              selectedUpgradePackage?.symbol +
              selectedUpgradePackage?.display_billed_today,
          })}
        </div>

        {selectedUpgradePackage?.tax_percentage && (
          <div className="tax-label">
            <Trans
              i18nKey={"upgrade.includes_n_tax"}
              values={{
                taxValue: selectedUpgradePackage?.tax_percentage,
                taxType: selectedUpgradePackage?.tax_label,
              }}
            />
          </div>
        )}

        {selectedUpgradePackage && (
          <div className="billed-info">
            <Trans
              i18nKey={"upgrade.billed_desc_2"}
              values={{
                amount: `${selectedUpgradePackage?.symbol}${selectedUpgradePackage?.renews_amount}`,
                duration: selectedUpgradePackage?.period_length * 30,
                renewDate: selectedUpgradePackage?.renews_at,
              }}
              components={{
                span: <span />,
                doublesize: <span className="doublesize" />,
              }}
            />
          </div>
        )}

        <div className="cancellation-info">{t("upgrade.billed_cancel")}</div>

        <div
          className="terms-and-condition-button"
          onClick={() => onNavigate(routeConst.terms.path)}
        >
          {t("upgrade.terms_conditions")}
        </div>

        <UpgradePromoDialog />
      </div>
    );
  } else if (getPwaPaymentsError) {
    if (getPwaPaymentsErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default Upgrade;
