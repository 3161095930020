// General
import "./geolocation-permission-prompt-dialog.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import { useUpdateUserLocationMutation } from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGeolocationPermissionPromptDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, ButtonBase } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// moment
import moment from "moment";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GeolocationPermissionPromptDialog = () => {
  // API variables
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // General variables
  const [backdropCounter, setBackdropCounter] = useState(0);

  // Redux variables
  const geolocationPermissionPromptDialog = useSelector(
    (state) => state.dialog.geolocationPermissionPromptDialog
  );
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (geolocationPermissionPromptDialog) {
    } else {
    }
  }, [geolocationPermissionPromptDialog]);

  // Lifecycle | Check for update | backdropCounter | Easter Egg to close dialog
  useEffect(() => {
    if (backdropCounter < 10) return;

    dispatch(updateGeolocationPermissionPromptDialog(false));
  }, [backdropCounter]);

  // Event Handlers | Button
  const onPromptPermission = () => {
    getGpsLocation();
    onCloseDialog();
  };
  const onLearnMore = () => {
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    setBackdropCounter((prevBackdropCounter) => prevBackdropCounter + 1);

    if (reason === "backdropClick") return;

    dispatch(updateGeolocationPermissionPromptDialog(false));
  };

  // Utility Functions
  const getGpsLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const apiKey = process.env["REACT_APP_GOOGLE_MAPS_API_KEY"];
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              const place = data.results[0];
              let location_data = {
                formatted_address: place.formatted_address,
              };

              if (place?.address_components) {
                for (let i = 0; i < place.address_components.length; i++) {
                  switch (place.address_components[i].types[0]) {
                    case "locality":
                      location_data.city =
                        place.address_components[i].long_name;
                      break;
                    case "neighborhood":
                      if (location_data.city !== null) {
                        location_data.neighborhood =
                          place.address_components[i].long_name;
                      }
                      break;
                    case "administrative_area_level_1":
                      location_data.state =
                        place.address_components[i].short_name;
                      break;
                    case "country":
                      location_data.country =
                        place.address_components[i].long_name;
                      location_data.country_iso =
                        place.address_components[i].short_name;
                      break;
                    default:
                      break;
                  }
                }
              }

              // Save to local storage
              const locationDataObj = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                location_data,
                lastRequested: moment().toDate(),
              };
              sessionService.setLocationData(locationDataObj);

              // Call Update Location API | Only if isLoggedIn = true
              if (isLoggedIn) {
                const locationObj = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  location_data: location_data,
                  type: "device_gps",
                };
                updateUserLocation(locationObj);
              }

              dispatch(updateGeolocationPermissionPromptDialog(false));
            })
            .catch((error) => {
              console.log(error);
            });
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              break;
            case error.POSITION_UNAVAILABLE:
              break;
            case error.TIMEOUT:
              break;
            case error.UNKNOWN_ERROR:
              break;
            default:
              break;
          }
        }
      );
    } else {
    }
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={geolocationPermissionPromptDialog}
      onClose={onCloseDialog}
    >
      <div id="geolocation-permission-prompt-dialog">
        <div className="padding-container">
          <div className="location-permission-icon-container">
            {getIcon("locationPermissionIcon", "location-permission-icon")}
          </div>

          <div className="geolocation-prompt-title">
            {t("location.location_permission_prompt_title")}
          </div>
          <div className="geolocation-prompt-desc">
            {t("location.location_permission_prompt_desc")}
          </div>

          <div className="buttons-container">
            <ButtonBase
              className="allow-button"
              component="div"
              onClick={onPromptPermission}
            >
              {t("common.allow")}
            </ButtonBase>

            {false && (
              <div className="learn-more-button" onClick={onLearnMore}>
                {t("common.learn_more")}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GeolocationPermissionPromptDialog;
