// General
import "./liveness-verification.scss";
import { useState, useEffect } from "react";
// Service
import {
  useLazyGetProfileQuery,
  useInitiateFacialVerificationV2Mutation,
  useVerifyFacialVerificationV2Mutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateInfoToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// Amplify
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { ThemeProvider } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const LivenessVerification = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    initiateFacialVerificationV2,
    {
      data: initiateFacialVerificationV2Data,
      error: initiateFacialVerificationV2ErrorData,
      isLoading: initiateFacialVerificationV2Loading,
      isSuccess: initiateFacialVerificationV2Success,
      isError: initiateFacialVerificationV2Error,
    },
  ] = useInitiateFacialVerificationV2Mutation();
  const [
    verifyFacialVerificationV2,
    {
      data: verifyFacialVerificationV2Data,
      error: verifyFacialVerificationV2ErrorData,
      isLoading: verifyFacialVerificationV2Loading,
      isSuccess: verifyFacialVerificationV2Success,
      isError: verifyFacialVerificationV2Error,
    },
  ] = useVerifyFacialVerificationV2Mutation();

  // General variables
  const [isNext, setIsNext] = useState(false);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();
  const getIcon = IconManager();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    initiateFacialVerificationV2();
  }, []);

  // Lifecycle | Check for update | initiateFacialVerification API Response
  useEffect(() => {
    if (initiateFacialVerificationV2Loading) {
    } else if (initiateFacialVerificationV2Success) {
      if (initiateFacialVerificationV2Data?.status === -1) {
        const infoToastObj = {
          message: initiateFacialVerificationV2Data?.message,
          autoClose: 4000,
        };
        dispatch(updateInfoToast(infoToastObj));
        onNavigate(-1, true);
      }
    }
  }, [
    initiateFacialVerificationV2Loading,
    initiateFacialVerificationV2Success,
    initiateFacialVerificationV2Error,
  ]);

  // Lifecycle | Check for update | verifyFacialVerificationV2 API Response
  useEffect(() => {
    if (verifyFacialVerificationV2Loading) {
    } else if (verifyFacialVerificationV2Success) {
      switch (verifyFacialVerificationV2Data?.status) {
        case 1:
          // Success
          onNavigate(routeConst.verify.sugarbookVerified.status.approved.path);
          break;
        case -1:
          // Rejected
          onNavigate(routeConst.verify.sugarbookVerified.status.rejected.path);
          break;
        case -3:
          // Existing face found
          onNavigate(
            routeConst.verify.sugarbookVerified.status.rejected.duplicate
          );
          break;
        default:
          const defaultInfoToastObj = {
            message: verifyFacialVerificationV2Data?.message,
            autoClose: 3000,
          };
          dispatch(updateInfoToast(defaultInfoToastObj));
          break;
      }
    } else if (verifyFacialVerificationV2Error) {
    }
  }, [
    verifyFacialVerificationV2Loading,
    verifyFacialVerificationV2Success,
    verifyFacialVerificationV2Error,
  ]);

  // Event Handler | Rekognition
  const handleAnalysisComplete = async () => {
    const obj = {
      code: initiateFacialVerificationV2Data?.data?.facial_verification?.code,
    };
    verifyFacialVerificationV2(obj);
  };
  const onFaceLivenessDetectorError = (error) => {
    console.log(error);
    setIsNext(false);

    let errorToastObj = {};
    if (error?.state === "CAMERA_ACCESS_ERROR") {
      errorToastObj = {
        message: t("verification.camera_access_error"),
        autoClose: 3000,
      };
    } else {
      errorToastObj = {
        message: error?.error?.message,
        autoClose: 3000,
      };
    }
    dispatch(updateErrorToast(errorToastObj));

    initiateFacialVerificationV2();
  };
  const onFaceLivenessDetectorCancel = () => {
    setIsNext(false);
    initiateFacialVerificationV2();
  };

  // Event Handlers | Button
  const onBeginCheck = () => {
    if (!initiateFacialVerificationV2Data?.data?.facial_verification?.code)
      return;

    setIsNext(true);
  };

  return (
    <div id="liveness-verification-page">
      {initiateFacialVerificationV2Loading ? (
        <Spinner />
      ) : !isNext ? (
        <div className="max-width-container">
          <div className="liveness-verification-tutorial-container">
            <div className="top-container">
              {!getProfileData?.data?.verifications?.facial?.verified &&
                !getProfileData?.data?.verifications?.facial
                  ?.verification_required && (
                  <div className="navbar-container">
                    <div
                      className="back-button"
                      onClick={() => onNavigate(routeConst.search.path)}
                    >
                      {getIcon("signupBackIcon", "signup-back-icon")}
                    </div>
                  </div>
                )}

              <div className="liveness-check-title">
                {t("verification.liveness_check")}{" "}
                <span>
                  {getIcon("verifiedProfileBadge", "verified-profile-badge")}
                </span>
              </div>

              <div className="liveness-check-description">
                {t("verification.liveness_check_desc")}
              </div>

              {/* <div className="photosensitivity-container">
                <div className="photosensitivity-title">
                  {t("verification.photosensitivity_warning_title")}
                </div>

                <div className="photosensitivity-description">
                  {t("verification.photosensitivity_warning_desc")}
                </div>
              </div> */}

              <div className="liveness-follow-instruction-image-container">
                <div className="good-fit-container">
                  <div className="good-fit-image-container">
                    <img
                      className="good-fit-image"
                      src={getAsset("livenessGoodFitBackground")}
                      alt="good-fit"
                    />

                    {getIcon("goodFitTickIcon", "good-fit-tick-icon")}
                  </div>
                  <div className="good-fit-label">
                    {t("verification.good_fit")}
                  </div>
                </div>

                <div className="too-far-container">
                  <div className="too-far-image-container">
                    <img
                      className="too-far-image"
                      src={getAsset("livenessTooFarBackground")}
                      alt="too-far"
                    />

                    {getIcon("tooFarCrossIcon", "too-far-cross-icon")}
                  </div>
                  <div className="too-far-label">
                    {t("verification.too_far")}
                  </div>
                </div>
              </div>

              <div className="how-to-verify-container">
                <div className="how-to-verify-title">
                  {t("verification.how_to_verify")}
                </div>

                <ol className="liveness-follow-instruction-list-container">
                  <li className="liveness-follow-instruction-list">
                    {t("verification.liveness_instruction_1")}
                  </li>

                  <li className="liveness-follow-instruction-list">
                    {t("verification.liveness_instruction_2")}
                  </li>

                  <li className="liveness-follow-instruction-list">
                    {t("verification.liveness_instruction_3")}
                  </li>

                  {/* <li className="liveness-follow-instruction-list">
                    {t("verification.liveness_instruction_4")}
                  </li> */}
                </ol>
              </div>
            </div>

            <div className="bottom-container">
              <div
                className={`begin-check-button ${
                  initiateFacialVerificationV2Data?.data?.facial_verification
                    ?.code
                    ? ""
                    : "disabled-button"
                }`}
                onClick={onBeginCheck}
              >
                {t("verification.lets_begin")}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ThemeProvider>
          <FaceLivenessDetector
            sessionId={
              initiateFacialVerificationV2Data?.data?.facial_verification?.code
            }
            region={initiateFacialVerificationV2Data?.data?.region}
            onAnalysisComplete={handleAnalysisComplete}
            onError={onFaceLivenessDetectorError}
            onUserCancel={onFaceLivenessDetectorCancel}
            disableStartScreen={false}
            displayText={{
              waitingCameraPermissionText: t(
                "verification.waiting_camera_permission_text"
              ),
              hintCenterFaceText: t("verification.hint_center_face_text"),
              photosensitivyWarningHeadingText: t(
                "verification.photosensitivity_warning_title"
              ),
              photosensitivityWarningBodyText: t(
                "verification.photosensitivity_warning_desc"
              ),
              startScreenBeginCheckText: t(
                "verification.start_screen_begin_check_text"
              ),
              hintConnectingText: t("verification.hint_connecting_text"),
              hintTooFarText: t("verification.hint_too_far_text"),
              hintHoldFaceForFreshnessText: t(
                "verification.hint_hold_face_for_freshness_text"
              ),
              hintVerifyingText: t("verification.hint_verifying_text"),
              photosensitivityWarningInfoText: t(
                "verification.photosensitivity_warning_info_text"
              ),
            }}
          />
        </ThemeProvider>
      )}
    </div>
  );
};

export default LivenessVerification;
